<template>
  <span class="social-media" :style="jcObj">
    <a href="https://twitter.com/logexa" target="_blank" aria-label="twitter">
      <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M25.1128 2.2074C24.2082 2.5774 23.2365 2.8274 22.2149 2.9404C23.269 2.35819 24.0576 1.44187 24.4336 0.362402C23.4432 0.905391 22.3594 1.2876 21.2291 1.4924C20.469 0.743267 19.4622 0.246728 18.3651 0.0798757C17.268 -0.0869765 16.1418 0.0851932 15.1616 0.569654C14.1813 1.05412 13.4017 1.82376 12.9439 2.75911C12.4861 3.69445 12.3756 4.74315 12.6296 5.7424C10.6229 5.6494 8.65983 5.16795 6.86777 4.32931C5.07572 3.49066 3.49473 2.31356 2.2274 0.874402C1.79406 1.5644 1.5449 2.3644 1.5449 3.2164C1.54441 3.9834 1.74903 4.73864 2.1406 5.41513C2.53217 6.09162 3.09857 6.66844 3.78956 7.0944C2.9882 7.07086 2.20451 6.87098 1.50373 6.5114V6.5714C1.50365 7.64714 1.90677 8.68978 2.64468 9.5224C3.38259 10.355 4.40985 10.9263 5.55215 11.1394C4.80875 11.3251 4.02935 11.3525 3.27281 11.2194C3.5951 12.145 4.22289 12.9544 5.0683 13.5343C5.9137 14.1142 6.93439 14.4356 7.98748 14.4534C6.1998 15.7488 3.99203 16.4515 1.71932 16.4484C1.31673 16.4485 0.914481 16.4268 0.514648 16.3834C2.82158 17.7526 5.50702 18.4792 8.24965 18.4764C17.5338 18.4764 22.6092 11.3784 22.6092 5.2224C22.6092 5.0224 22.6038 4.8204 22.5941 4.6204C23.5813 3.96138 24.4335 3.1453 25.1106 2.2104L25.1128 2.2074Z"
          :fill="color" />
      </svg>
    </a>
    <a :href="'https://api.whatsapp.com/send?phone=' + phoneNumber" target="_blank" aria-label="whatsapp">
      <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.9295 5.35776C16.7881 0.270218 10.3746 -1.24229 5.40077 1.92023C0.55787 5.08275 -1.0128 11.9578 2.12854 17.0453L2.39032 17.4578L1.34321 21.5829L5.26989 20.4829L5.66255 20.7579C7.36412 21.7204 9.19657 22.2704 11.029 22.2704C12.9924 22.2704 14.9557 21.7204 16.6573 20.6204C21.5002 17.3203 22.9399 10.5828 19.9295 5.35776ZM17.1808 15.9453C16.6573 16.7703 16.0028 17.3203 15.0866 17.4578C14.563 17.4578 13.9086 17.7329 11.2908 16.6328C9.06568 15.5328 7.23323 13.7453 5.92433 11.6828C5.139 10.7203 4.74633 9.48279 4.61544 8.24528C4.61544 7.14527 5.00811 6.18276 5.66255 5.49526C5.92433 5.22026 6.18611 5.08275 6.44789 5.08275H7.10234C7.36412 5.08275 7.62589 5.08275 7.75678 5.63276C8.01856 6.32026 8.67301 7.97028 8.67301 8.10778C8.8039 8.24528 8.8039 8.52028 8.67301 8.65778C8.8039 8.93278 8.67301 9.20779 8.54212 9.34529C8.41123 9.48279 8.28034 9.75779 8.14945 9.89529C7.88767 10.0328 7.75678 10.3078 7.88767 10.5828C8.41123 11.4078 9.06568 12.2328 9.72012 12.9203C10.5055 13.6078 11.2908 14.1578 12.207 14.5703C12.4688 14.7078 12.7306 14.7078 12.8615 14.4328C12.9924 14.1578 13.6468 13.4703 13.9086 13.1953C14.1704 12.9203 14.3012 12.9203 14.563 13.0578L16.6573 14.1578C16.919 14.2953 17.1808 14.4328 17.3117 14.5703C17.4426 14.9828 17.4426 15.5328 17.1808 15.9453Z"
          :fill="color" />
      </svg>
    </a>
    <a href="https://www.facebook.com/Logexa" target="_blank" aria-label="facebook">
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.4471 0.270386H1.55292C1.27431 0.272566 1.00769 0.390037 0.810682 0.597419C0.613669 0.804801 0.502072 1.08544 0.5 1.37872V19.1621C0.502072 19.4553 0.613669 19.736 0.810682 19.9434C1.00769 20.1507 1.27431 20.2682 1.55292 20.2704H10.6492V12.5371H8.17917V9.51205H10.6492V7.28705C10.6492 4.70372 12.1454 3.29539 14.3463 3.29539C15.0825 3.29539 15.8188 3.29539 16.555 3.41205V6.10372H15.0429C13.8475 6.10372 13.6179 6.70372 13.6179 7.57872V9.50372H16.4679L16.0958 12.5287H13.6179V20.2704H18.4471C18.7257 20.2682 18.9923 20.1507 19.1893 19.9434C19.3863 19.736 19.4979 19.4553 19.5 19.1621V1.37872C19.4979 1.08544 19.3863 0.804801 19.1893 0.597419C18.9923 0.390037 18.7257 0.272566 18.4471 0.270386Z"
          :fill="color" />
      </svg>
    </a>
    <a href="https://sa.linkedin.com/company/logexa" target="_blank" aria-label="linkedin">
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0.5 1.9413C0.5 1.49814 0.66724 1.07314 0.964928 0.759786C1.26262 0.44643 1.66637 0.270388 2.08736 0.270388H17.9109C18.1195 0.270029 18.3262 0.312991 18.519 0.396812C18.7119 0.480634 18.8871 0.60367 19.0347 0.758875C19.1823 0.914081 19.2994 1.09841 19.3792 1.30131C19.4591 1.5042 19.5001 1.72168 19.5 1.9413V18.5977C19.5002 18.8173 19.4593 19.0349 19.3796 19.2379C19.2999 19.4408 19.1829 19.6253 19.0354 19.7807C18.8879 19.936 18.7127 20.0593 18.5199 20.1433C18.3271 20.2273 18.1204 20.2705 17.9118 20.2704H2.08736C1.87884 20.2704 1.67235 20.2271 1.47971 20.1431C1.28706 20.0591 1.11203 19.9359 0.964623 19.7807C0.817211 19.6254 0.700305 19.4411 0.620583 19.2383C0.540861 19.0354 0.499887 18.8181 0.5 18.5986V1.9413ZM8.02055 7.89584H10.5933V9.25584C10.9647 8.47402 11.9147 7.77039 13.3423 7.77039C16.0791 7.77039 16.7277 9.32766 16.7277 12.1849V17.4777H13.958V12.8358C13.958 11.2086 13.5867 10.2904 12.6436 10.2904C11.3352 10.2904 10.7911 11.2804 10.7911 12.8358V17.4777H8.02055V7.89584ZM3.27055 17.3531H6.04109V7.77039H3.27055V17.3522V17.3531ZM6.4375 4.64493C6.44272 4.89464 6.40051 5.14292 6.31334 5.37522C6.22617 5.60752 6.0958 5.81915 5.92988 5.99769C5.76396 6.17623 5.56583 6.31809 5.34712 6.41494C5.12841 6.51179 4.89353 6.56168 4.65625 6.56168C4.41898 6.56168 4.18409 6.51179 3.96538 6.41494C3.74667 6.31809 3.54854 6.17623 3.38262 5.99769C3.2167 5.81915 3.08633 5.60752 2.99916 5.37522C2.91199 5.14292 2.86978 4.89464 2.875 4.64493C2.88525 4.1548 3.07742 3.68838 3.41036 3.34556C3.74329 3.00273 4.19051 2.81077 4.65625 2.81077C5.12199 2.81077 5.56921 3.00273 5.90214 3.34556C6.23508 3.68838 6.42725 4.1548 6.4375 4.64493Z"
          :fill="color" />
      </svg>
    </a>

  </span>
</template>

<script >
 

export default  {
  props: {
    color: {
      default: '#2F3A4A'
    },
    justifyContent: {
      default: 'flex-start'
    }
  },

  data: () => ({
    phoneNumber: '966550800510'
  }),

  computed: {
    jcObj: function () {
      return {
        'justify-content': this.justifyContent
      }
    }
  }
} 
</script>

<style lang="scss" scoped>
.social-media {
  display: flex;
  align-items: center;
  gap: 20px;

  a:hover {
    path {
      fill: $PRIMARY_COLOR !important;
    }
  }
}</style>
