<template>
    <div class="video-wrapper" @click.prevent.stop="togglePlay()">
        <div class="video">
            <iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="100%" height="443"
                type="text/html" allow="autoplay"
                src="https://www.youtube.com/embed/2G6DE4R9G2w?autoplay=1&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=1&start=0&end=0&origin=http://youtubeembedcode.com"></iframe>
        </div>
    </div>
</template>

<script  >
 

export default {
    data: function () {
        return {
            video: null,
            isControlPreviewed: true,
        };
    },
    methods: {
        togglePlay: function () {
            if (this.video) {
                if (this.video.paused) {
                    this.video.play();
                    this.video.setAttribute("controls", true);
                    this.isControlPreviewed = false;
                } else {
                    this.video.pause();
                    this.video.removeAttribute("controls");
                    this.isControlPreviewed = true;
                }
            }
        },
    },
    onMounted() {
      
        this.video = document.querySelector("video");
        console.log(this.video);
           // this.video.play();
    },
};
</script>

<style lang="scss" scoped>
.video-wrapper {
    width: 100%;
    margin: 90px 0px 50px;
    position: relative;
    cursor: pointer;

    .play-icon {
        position: absolute;
        top: 50%;
        inset-inline-start: 50%;
        transform: translate(-50%, -50%);
        width: 10%;
        z-index: 99;
    }

    video {
        width: 100%;
    }

    iframe {
        border-radius: 8px;
    }
}
</style>
