<template>
    <section class="blog">
        <div class="headingSection">
            <p class=" text-xlarge fw-500 text-start" style="color: #0000E2 !important;">{{
                $t("landingPage.BlogSec.title")
                }}</p>
            <p class="font-18 fw-400 text-start">{{ $t("landingPage.BlogSec.desc") }}</p>
            <div class="next-icon" @click="moveCards" v-if="visibleArticles.length >=3">
                <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 17.6211H27" stroke="black" stroke-width="2.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path d="M18 7.70947L27 17.6214L18 27.5333" stroke="black" stroke-width="2.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </div>
        </div>

        <transition-group name="blog-card" tag="div" class="blog-card-container gridBlog products-container">
            <BlogCard v-for="post in visibleArticles" :key="post.id" :article="post" class="product-item" />
        </transition-group>

    </section>
</template>

<style lang="scss" scoped>
.next-icon {
    background: #FFFFFF;
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.1);
    border-radius: 58px;
    width: 64px;
    height: 67.24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0px;
    top: 82px;
    z-index: 3;
    @media screen and (max-width: 1500px) {
        display: none;


    }
}
html[dir="rtl"]{
.next-icon {
    
    right: unset;
    left: 0;
    svg {
        transform: rotate(180deg);
    }
}
}

.blog {
    background: #F8F9FB;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 0px;


    @media screen and (max-width: 1500px) {
        width: 100%;
        padding: 0 80px;


    }

    @media screen and (max-width:1200px) {
        width: 100%;
        padding: 120px 24px;


    }

    @media screen and (max-width: 700px) {
        width: 100%;
        padding: 120px 16px;


    }
}

.headingSection {
    width: 1440px;
    position: relative;

    @media screen and (max-width: 1500px) {
        width: 1200px;

        padding: 0 24px;


    }

    @media screen and (max-width:1200px) {
        width: 100%;
        padding: 0 24px;


    }

    h2 {
        font-weight: 500;

        @media screen and (max-width:1200px) {
            font-size: 32px;


        }
    }

    p {
        margin-top: 16px;
        color: #5D5D5D;
    }
}

.gridBlog {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    width: 1440px;
    margin-top: 32px;

    @media screen and (max-width: 1500px) {
        width: 1200px;
        grid-gap: 30px;
        grid-template-columns: repeat(3, 1fr);



    }

    @media screen and (max-width:1200px) {
        width: 100%;
        grid-gap: 30px;
        grid-template-columns: repeat(2, 1fr);



    }

    @media screen and (max-width: 700px) {
        display: grid;
        width: 100%;
        gap: 16px;
        grid-template-columns: repeat(1, 1fr);



    }
}

.blog-card-move-enter-active,
.blog-card-move-leave-active {
    transition: all 0.3s ease;
}

.blog-card-move-enter,
.blog-card-move-leave-to {
    opacity: 0;
    transform: translateX(50%);
}
</style>

<script >
import blogs from "@/api/blogs";
 import BlogCard from "@/components/home/BlogCard.vue";
//import { mapState } from "vuex";
export default  {
    props: ["translationKey"],
    components: {
        BlogCard,
    },
    data: () => ({
        posts: [] ,
        visibleArticles: [] ,
        visibleCount: 4,
        articls: { data: [], total: 0}
    }),
    watch: {
        "$i18n.locale": function (newVal) {
            this.loadPosts(newVal);
        },
        // articls: function () {
        //     this.updateVisibleArticles();
        // },
    },
    methods: {
        loadPosts: function (lang = "") {
            let params = {
                page: 1,
                pageSize: this.visibleCount + 1,
                lang: lang ? lang : this.$i18n.locale,
                slice: this.visibleCount,
            };
          //  this.$store.dispatch("getArticls", params);
            this.getArticls(params)
        },
        async getArticls({ lang, page = 1, pageSize = 9, slice = 0, category_id = '' }) {
            try {
                // Get selected number
                const response = await blogs.get_posts(lang, page, pageSize, category_id);

                if (response) {
                    if (slice !== 0) {
                        const slicedData = response.data.data.slice(0, slice);
                        const total = response.data.total;
                        //  commit('fillArticls', { data: slicedData, total });
                        this.articls = { data: slicedData, total };
                    } else {
                        const data = response.data.data;
                        const total = response.data.total;
                        // commit('fillArticls', { data, total });
                        this.articls = { data, total };
                    }
                    this.updateVisibleArticles();
                }
            } catch (error) {
                console.error('Error fetching articles:', error);
                throw error; // Rethrow the error to handle it in the component if needed
            }
        },
        updateVisibleArticles: function () {
            this.visibleArticles = this.articls.data.slice(1, this.visibleCount + 1);
        },
        moveCards: function () {
           // this.visibleCount++;
           // if (this.visibleCount > this.articls.length) {
          //      this.visibleCount = 1;
          // }
            //  this.updateVisibleArticles();
            debugger;
            this.$router.push('/blog');
        },
    },
    mounted: function () {
        this.loadPosts();
    },
    computed: {
       // ...mapState(["articls"]),
    },
} ;
</script>