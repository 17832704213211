<template>
    <div class="w-100 position-relative py-5">
        <div class="bg-image-1">

        </div>
        <div class="container">

            <div class=" contact-us-card">



                <div class="description-section">
                    <div class="cardHeading  text-xlarge fw-700">{{ $t("contact_us_card.contact_us") }}</div>
                    <p>{{ $t("contact_us_card.contact_us_description") }}</p>

                    <div class="text-start">

                        <!-- <CommonButton translationKey="lets_have_a_conversation" /> -->
                        <router-link to="/contact-us">
                            <button class="btn btn-warning mb-3">{{ $t('contact_us_card.lets_have_a_conversation')
                                }}</button>
                        </router-link>
                    </div>
                </div>
                <img :src="contactUs" alt="Contact Us Card Image" loading="lazy" class="contact-us-card-image" />


            </div>
        </div>
    </div>


</template>

<style lang="scss" scoped>
section {
    padding-bottom: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 786px) {
        width: 100%;
        padding: 0px 24px;
    }

    @media screen and (max-width: 786px) {
        width: 100%;
        padding: 0px 16px;
    }
}

.contact-us-card {
    background-color: #fff;
    //width: 1464px;
    height: 312px;

    box-shadow: 0px 32px 40px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
      //  gap: 34px;
    align-items: center;
    position: relative;

    @media screen and (max-width: 1500px) {
        width: 100%;
        height: 100%;
    }


    @media screen and (max-width: 900px) {
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 48px 0px 28px;
    }

    .contact-us-card-image {
        position: relative;

        max-width: 350px;
        width: 350px;
        @media screen and (max-width: 1100px) {
            width: 100%;
            //height: 241px;
            position: relative;
            bottom: 0;
            right: 0;
            margin-top: 10px;
            object-fit: cover;
            width: 250px;
        }
    }

    .description-section {
       // width: 770px;
        padding: 0px 56px;


        @media screen and (max-width: 1100px) {
            width: 100%;
            padding: 0px 24px;
        }
    }

    .cardHeading {
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 39px;
       
        background: transparent !important;
        color: $PRIMARY_TEXT_COLOR2;
        margin: 0;
        padding: 0;
        text-align: start;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: $SECONDARY_TEXT_COLOR4;
        margin-top: 16px;
        text-align: start;
    }
}
@media (max-width: $breakpoint-medium) {

    .description-section,
    .cardHeading,
    .text-start,
    h2,
    p {
        text-align: center !important;
        justify-content: center !important;
        align-items: center !important;
    }
}
</style>

<script >
import contactUs from "@/assets/images/svg/contactUs.svg"
export default  {
    props: ["translationKey", "link"],
    layout: "website_v3_layout",
    name: "ContactUs",
    data() {
        return {
            contactUs: contactUs,
        };
    },
};
</script>
