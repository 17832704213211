<template>
    <div class="container-fluid position-relative">
        <div class="row position-absolute w-100 h-100 bg-color">
            <div class="col-4 position-relative">
                <div class="striped-gradient"></div>

            </div>
            <div class="col-4">

            </div>
            <div class="col-4 position-relative">

                <div class="bg-image-1">

                </div>
            </div>
        </div>

        <div class="row position-relative py-4 color-white">

            <div class="col-12 text-center">
                <h2 class="fw-700 text-xlarge py-3">
                    {{ $t('Hero.WhBenefit') }}
                </h2>

                <p class="fw-400 text-small-mid my-2">
                    {{ $t('Hero.WhBenefit_desc') }}
                </p>

                <!-- <button class="btn btn-warning my-4">{{ $t('Hero.JoinUsAsSP') }}</button> -->
                <router-link class="btn btn-warning my-4" to="/services">{{ $t('Hero.JoinUsAsSP') }}</router-link>

            </div>

        </div>
    </div>
</template>

<script>
export default {
    name:"BeWithUs"
}
</script>

<style lang="scss" scoped>
.bg-color{
    height: 200px;
    background: #101828;
}
.striped-gradient,.bg-image-1{
    width: 200px;
    height: 200px;
    z-index: 1 !important;
    opacity: 0.5;
}
.striped-gradient{
   background-image: linear-gradient(to bottom right, #101828, rgb(36 32 32 / 0%)),
    radial-gradient(farthest-side at 50% 50%, rgb(249 232 0 / 30%), #101828) !important
}

.bg-image-1{
    background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0), #101828),
        radial-gradient(farthest-side at 0% 50%, rgb(82 82 178 / 68%), rgba(0, 0, 255, 0)) !important
}

.color-white{
    color: #fff;
    z-index: 2 !important;
}
 @media (max-width: $breakpoint-small) {

     .bg-image-1,
     .striped-gradient {
         width: 124px !important;
         height: 128px !important;
     }
 }
</style>