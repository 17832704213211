import axios from 'axios';

const apiClient = axios.create({
    baseURL: "https://platform.logexa.com/api",
    headers: {
        'Content-Type': 'application/json',
    },
});

export default apiClient;
