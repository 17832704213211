<template>
    <article @click="navigateToArticle">
        <div class="w-100">
            <img class="mainImg"
                :src="article.media && article.media.length ? article.media[0].original_url : article_placeholder"
                @error="$event.target.src = article_placeholder" :alt="article.title" :title="article.title">
            <div class="desc-section text-start">
                <p class="publish text-start">
                    {{ article.published_at }}
                </p>
                <p class="blogHeading text-start color-primary text-small-mid">{{ article.title }}</p>
                <p class="desc text-start" v-if="article.description">{{ article.description.slice(0, 153) }}<span
                        v-if="article.description.length > 153">...</span></p>

            </div>
        </div>

        <button>{{
            $t('common.show_article') }}
            &nbsp;&nbsp;

            <img :src="arrow" loading="lazy" width="16px" height="16px" style="height: 16px;" alt="Go">

        </button>
    </article>
</template>


<script >
 
import article_placeholder from "@/assets/images/main/article_placeholder.webp"
import arrow from "@/assets/images/svg/ArrowRight.svg"
export default {
    props: ['article'],
    data() {
        return {
            article_placeholder,
            arrow
        }
    },
    methods: {
        navigateToArticle: function () {
            localStorage.setItem('article', JSON.stringify(this.article))
            this.$router.push('/blog/' + this.article.slug)
        }
    }
} 
</script>


<style lang="scss" scoped>
article {
    width: 360px;
    padding: 24px;
    height: 439px;
    cursor: pointer;
    background: #FFFFFF;
    display: flex;
        flex-direction: column;
        justify-content: space-between;
    @media screen and (max-width: 1100px) {
        width: 100%;
        height: auto;
        padding: 16px;


    }

    &:hover {
        box-shadow: 0px 32px 40px rgba(0, 0, 0, 0.1);
    }

    @media screen and (max-width: 700px) {
        &:hover {
            box-shadow: none;
        }

        margin-bottom: 16px;
    }

    .mainImg {
        //height: 148px;
        padding: 0px;
        margin: 0px;
        border-radius: 4px;
       width: 100%; height: auto; 
    }

    .desc-section {
        width: 280px;

        .blogHeading {
            font-weight: 500;
           // font-size: 18px;
            line-height: 22px;
            background-color: transparent !important;
            margin: 0px;
            padding: 0px;
            color: #0101E2;
        }

        .desc {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            margin: 8px 0px;
            padding: 0px;
            color: #212121;
        }

        .publish {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            margin: 32px 0px 8px;
            padding: 0;
            color: #AFAFAF
        }

       
    }
 button {
     font-weight: 400;
     font-size: 14px;
     line-height: 17px;
     background-color: transparent !important;
     color: $show-more-color;
     display: flex;
     align-items: center;
     padding: 0px;
     margin: 0px;
     border: none;
    justify-content: flex-end;
 }
}
.publish {
    color: #AFAFAF !important;
}
</style>