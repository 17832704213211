<template>
    <div class="w-100 position-relative">


        <div class="bg-image-1">

        </div>

        <div class="striped-gradient"></div>
        <div class="container">

            <div class="row p-md-3 paddign_30_20_15 m-0">
                <div class="col-md-6 text-section">
                    <p class="fw-600 text-mid color-primary p-text-1 py-2 custom_font">{{ $t('Hero.smallHead') }}</p>
                    <h1 class="title fw-600 pt-2 text-large-32">{{ $t('Hero.Title1') }} <span
                            class="color-primary fw-700 ">{{
                            $t('Hero.ForStoring') }}</span></h1>
                    <h1 class="title fw-700 pb-2 text-large-32 ">{{ $t('Hero.Title2') }}</h1>
                    <p class="title_desc text-small text-mid fw-400 mb-0 pt-2 color-desc custom_font">
                        {{ $t('Hero.desc_1') }}
                    </p>
                    <!-- <p class="title_desc text-small  text-mid fw-400 pb-2 color-desc">
                        {{ $t('Hero.desc_2') }}
                    </p> -->
                    <div class="w-100 " style="padding:32px 0 ;">
                        <a class="btn btn-primary text-small askQoutation fw-600"
                            :href="`${BASE_FRONT_URL}` + `/${$i18n.locale}` + '/dashboard/rfq' + `${refId ? '?ref=' + refId : ''}`">
                            {{ $t('Hero.askQoutation') }}
                        </a>
                        <!-- <button >

                        </button> -->
                        <router-link :to="'/contact-us' + `${refId ? '?ref=' + refId : ''}`">
                            <button class="btn customBtn">{{ $t('footer_v2.contact_us') }}</button>
                        </router-link>

                    </div>
                    <!-- <p>
                    تقدر من خلال لوجيكسا تخزن منتجاتك في مستودعات الرياض , جده , الدمام و المدن الاخرى بامان وبعقود
                    شهرية ويمكنك ايضا ادارة مخزونك من خلال منصتنا وانشاء الشحنات من والى المستودع.
                </p> -->
                </div>
                <div class="col-md-6 bg_image">
                    <!-- <button class="btn btn-primary btn-rounded  fw-600 text-base">
                        dffdf
                    </button>
                    <button class="btn btn-rounded btn-white fw-600 text-base">
                        dsfsdffdds
                    </button>
                    <button class="btn btn-warning btn-rounded fw-600 text-base">
                        dfdsffd
                    </button> -->


                    <!-- <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-6 custom-flex">
                            <div class="row padding-5">
                                <div class="col-md-12 d-flex justify-content-end p-0">
                                    <div class="bordered">
                                        <img :srcset="imageOneSrcset" :src="image_1" loading="eager" class="image_1"
                                            alt="Hero Section Image" />
                                            
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div class="col-md-6 col-6 bg_image">
                             <div class="row padding-5 ">
                                <div class="col-md-12 d-flex justify-content-start p-0">
                                    <div class="bordered  ">
                                        <img :srcset="imageTwoSrcset" :src="image_2" loading="eager" class="image_2"
                                            alt="Hero Section Image 2" />
                                    </div>
                                </div>

                            </div>
                            <div class="row padding-5 ">

                                <div class="col-md-12 d-flex justify-content-start p-0">
                                    <div class="bordered  ">
                                        <img :srcset="imageThreeSrcset" :src="image_3" loading="eager" class="image_3"
                                            alt="Hero Section Image 3" />
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div> -->




                    <!-- <div class="image-grid">
                    <div class="image-top">
                        <img :src="image_1" alt="Hero Section Image" />
                    </div>
                    <div class="image-left">
                        <img :src="image_2" alt="Hero Section Image 2" />
                    </div>
                    <div class="image-right">
                        <img :src="image_3" alt="Hero Section Image 3" />
                    </div>
                </div> -->
                </div>
            </div>
            <!-- <div class="row p-md-3 paddign_30_20_15 m-0">
                <div class="col-md-12">
                    <div class="card custom_card">
                        <div class="card-body p-4">
                            <div class="row">
                                <div class="col-md-3 col-sm-12 my-2">
                                    <select class="form-select" :aria-label="$t('Hero.SelectStorageType') ">
                                        <option disabled selected>{{ $t('Hero.SelectStorageType') }}</option>
                                        <option value="1">Option 1</option>
                                        <option value="2">Option 2</option>
                                        <option value="3">Option 3</option>
                                    </select>
                                </div>
                                <div class="col-md-3 col-sm-12 my-2">
                                    <select class="form-select" :aria-label="$t('Hero.SelectProductType') ">
                                        <option disabled selected>{{ $t('Hero.SelectProductType') }}</option>
                                        <option value="1">Option 1</option>
                                        <option value="2">Option 2</option>
                                        <option value="3">Option 3</option>
                                    </select>
                                </div>
                                <div class="col-md-3 col-sm-12 my-2">
                                    <select class="form-select" :aria-label="$t('Hero.SelectTemperature') ">
                                        <option disabled selected>{{ $t('Hero.SelectTemperature') }}</option>
                                        <option value="1">Option 1</option>
                                        <option value="2">Option 2</option>
                                        <option value="3">Option 3</option>
                                    </select>
                                </div>
                                <div class="col-md-3 col-sm-12 my-2">
                                    <button class="btn btn-primary text-small fw-600">
                                        {{ $t('Hero.CompleteTheQuotationRequest') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div> -->


        </div>

        <div class="container py-5">
            <div class="row p-md-3 paddign_30_20_15 m-0">
                <div class="col-md-4 d-flex  flex-column col-4 text-end align-end">
                    <div class="text-center" style="width: fit-content;">
                        <h4 class="fw-700 text-xxlarge">
                            5,000+
                        </h4>

                        <h5 class="fw-500 text-xlarge_custom">
                            {{ $t('Hero.StoredSku') }}
                        </h5>
                    </div>

                </div>
                <div class="col-md-4 d-flex  flex-column col-4">
                    <h4 class="fw-700 text-xxlarge">
                        380+
                    </h4>

                    <h5 class="fw-500 text-xlarge_custom">
                        {{ $t('Hero.ClientTrustsUs') }}
                    </h5>
                </div>
                <div class="col-md-4 d-flex  flex-column col-4 align-start">
                    <div class="text-center" style="width: fit-content;">
                        <h4 class="fw-700 text-xxlarge">
                            50+
                        </h4>

                        <h5 class="fw-500 text-xlarge_custom">
                            {{ $t('Hero.ReadyWarehouse') }}
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <p class="text-mid fw-600">
                        {{ $t('Hero.BrandsTrustUs') }} <span class="color-primary  fw-600">{{ $t('Hero.Logexa')
                            }}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import image_1 from '@/assets/images/main/image_1.webp';
// import image_1_small from '@/assets/images/main/image_1_small.webp';
// import image_1_mid from '@/assets/images/main/image_1_mid.webp';
//import image_1_mid from '@/assets/images/main/image_1_mid.webp';
// import image_2 from '@/assets/images/main/image_2.webp';
// import image_2_small from '@/assets/images/main/image_2_small.webp';
// import image_2_mid from '@/assets/images/main/image_2_mid.webp';


// import image_3 from '@/assets/images/main/image_3.webp';
// import image_3_small from '@/assets/images/main/image_3_small.webp';
// import image_3_mid from '@/assets/images/main/image_3_mid.webp';

// import image_2 from '@/assets/images/main/image_2.webp';
// import image_3 from '@/assets/images/main/image_3.webp';
import { env } from "@/static/env";
export default {
    name: 'HeroSection',
    props: {
        msg: String
    },
    data() {
        // return {
        //     image_1: image_1,
        //     image_1_mid: image_1_mid,
        //     image_1_small: image_1_small,

        //     image_2: image_2,
        //     image_2_mid: image_2_mid,
        //     image_2_small: image_2_small,


        //     image_3: image_3,
        //     image_3_mid: image_3_mid,
        //     image_3_small: image_3_small,
        //     // image_3: image_3,
        // }
        return {
            BASE_FRONT_URL: env.BASE_FRONT_URL
        }
    },
    computed: {
        refId() {
            return this.$route.query.ref;
        }
    },
    // computed: {
    //     imageOneSrcset() {
    //         // Define the srcset attribute based on screen size
    //         return `${this.image_1} 1024w,
    //           ${this.image_1_mid} 991w,
    //           ${this.image_1_small} 768w`;
    //     },

    //     imageTwoSrcset() {
    //         // Define the srcset attribute based on screen size
    //         return `${this.image_2} 1024w,
    //           ${this.image_2_mid} 991w,
    //           ${this.image_2_small} 768w`;
    //     },


    //     imageThreeSrcset() {
    //         // Define the srcset attribute based on screen size
    //         return `${this.image_3} 1024w,
    //           ${this.image_3_mid} 991w,
    //           ${this.image_3_small} 768w`;
    //     },

    // },
    // mounted() {
    //     let images = [this.image_1, this.image_2,this.image_3];
    //     for (let index = 0; index < images.length; index++) {
    //         const element = images[index];
    //         const preloadLink = document.createElement('link');
    //         preloadLink.rel = 'preload';
    //         preloadLink.as = 'image';
    //         preloadLink.href = element; // Use the logo from data
    //        // preloadLink.setAttribute('importance', 'high');
    //         preloadLink.setAttribute('fetchpriority', 'high');
    //         document.head.appendChild(preloadLink);
    //     }

    // }
    mounted() {
        // Create a link element for preloading the background image
       
        const preloadLink2 = document.createElement('link');
        preloadLink2.rel = 'preload';
        preloadLink2.as = 'image';
        preloadLink2.href = require('@/assets/images/main/all_en_1.avif'); // Use the correct path to your image
        preloadLink2.setAttribute('type', 'image/avif'); // Set the image type
        preloadLink2.setAttribute('fetchpriority', 'high');
        document.head.appendChild(preloadLink2);
    }
}
</script>

 
<style scoped lang="scss">
.custom_card{
    border: unset !important;
    border-radius: 16px;
    box-shadow: 0px 4px 20px 0px #00000012;
}
.text-section{
    text-align: start !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .p-text-1{
        text-align: start !important;
            color: $primary-color;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: start;

    }
    .title{
        text-align: start !important;
        color:$code-grey-3;
    }
}
 

 
.bg_image{
    background-image: url('../assets/images/main/all_en_1.avif');
    //background-position: cover;
        min-height: 640px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
}
// html[dir=ltr]{
//    .bg_image{
//     background-image: url('../assets/images/main/all_en_1.avif');
//    }
// }
 .image_1{
    position: relative;
    width: 100%;
    max-height: 400px;
    max-width: 272px;
    height: auto;
    //aspect-ratio: 100 / 150;
 }
 
 .image_2,
 .image_3 {
     position: relative;
     width: 100%;
     max-height: 300px;
     max-width: 272px;
     height: auto;
     //aspect-ratio: 100 / 150;
 }

 .image_1 {
    position: relative;
        top: -13px;
        right: 12px;
 }
 .image_3,
 .image_2{
    position: relative;
        top: -13px;
        right: -12px;
 }



.bordered{
    border: 2px solid $primary-color;
    border-radius: 8px;
}
.custom-flex{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.padding-5 {
    padding: 25px;
}
 .paddign_30_20_15 {
     padding: 24px !important;
 }
@media (max-width: $breakpoint-medium) {

    .custom_card{
    .btn-primary {
        width: 100%;
    }
    }
    .text-section{
        text-align: center !important;
        .title, .p-text-1{
            text-align: center !important;
        }
    }
   
    .paddign_30_20_15{
        padding: 0px !important;
    }
 
    .image_1 {
            position: relative;
            top: -10px;
            right: 12px;
            max-width: 272px;
        }
    
        .image_3,
        .image_2 {
            position: relative;
            top: -10px;
            right: -12px;
            max-width: 272px;
        }
        .padding-5 {
            padding: 20px;
        }


        .text-section, h1,p{
            text-align: center !important;
            justify-content: center;
            align-items: center;
        }
}
@media (max-width: $breakpoint-small) {
    .askQoutation{
        width: 100%;
    }
    .paddign_30_20_15 {
            padding: 0px !important;
        }
 
    .bg_image {
            // background-image: url('../assets/images/main/all_en_1.avif');
            min-height:500px;
        }
    .image_1 {
        position: relative;
        top: -7px;
        max-width: 127px;
        right: 8px;
      //  max-height: 188px;
    }

    .image_3,
    .image_2 {
        position: relative;
        max-width: 129px;
        max-height: 129px;
        top: -7px;
        right: -8px;
    }
        .padding-5 {
            padding: 10px;
        }
}

// @media (min-width: $breakpoint-large) {
    
// }

.align-end{
    align-items: flex-end;
}
.btn-rounded{
    padding: 16px 24px 16px 24px;
    border-radius: 100px !important;

   // color: #363636;
}
.btn-white{
    background-color: #fff;
    color: $primary-color;
    border: unset;
}
.btn-white:hover {
    background-color: #fff;
    color: $primary-color !important;
    border: unset;
}

.customBtn{
    color: #363636;
    border: 0;
    background: unset;
    padding: 10px 25px 10px 25px;
}
html[dir='ltr']{
    .custom_font{
        @include font-size-responsive-custom($font-size-18)
    }
}
</style>
