<template>
    <section class="trustedBy">
        <section class="partners">
            <div class="slider">
                <div class="slider-track">
                    <div class="slide"></div>
                </div>
            </div>
        </section>
        <section class="partners">
            <div class="slider">
                <div class="slider-track">
                    <div class="slide2"></div>
                </div>
            </div>
        </section>
    </section>
</template>

<style lang="scss" scoped>
.partners {
    height: 123px;

    @media screen and (max-width: 1100px) {
        padding-top: 200px;
    }

    @media screen and (max-width: 820px) {
        padding-top: 0px;
    }




    .slider {
        position: absolute;
        overflow: hidden;
        width: 100%;
        inset-inline-start: 0;
        height: 134px;
        display: grid;
        align-items: center;

        .slider-track {
            .slide {
                height: 134px;
                cursor: pointer;
                background-image: url('@/assets/images/main/strip-1.avif');
                background-repeat: repeat-x;
                animation: marque2e 100s infinite linear;

                &:hover {
                    animation-play-state: paused;
                }

                @keyframes marque2e {
                    0% {
                        background-position: 0;
                    }

                    100% {
                        background-position: -6700px;
                    }
                }
            }

            .slide2 {
                height: 134px;
                cursor: pointer;
                background-image: url('@/assets/images/main/strip-2.avif');
                background-repeat: repeat-x;
                animation: oppositeMarquee 100s infinite linear;

                &:hover {
                    animation-play-state: paused;
                }

                @keyframes oppositeMarquee {
                    0% {
                        background-position: -6700px;
                    }

                    100% {
                        background-position: 0px;
                    }
                }
            }
        }
    }
}

.trustedBy {
    background: $code-background;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 0px;
}

.headingSection {
    width: 1440px;

    @media screen and (max-width: 1500px) {
        width: 1200px;

        padding: 0 24px;


    }

    @media screen and (max-width: 1100px) {
        width: 100%;
        padding: 0 24px;


    }


    h2 {
        font-weight: 500;

        @media screen and (max-width: 1100px) {
            font-size: 32px;


        }
    }

    p {
        margin-top: 16px;
        color: #5D5D5D;
    }
}
</style>

<script >
// import Vue from 'vue';


// export default Vue.extend({
// });
export default {
    name: 'TrustedBy',
}
</script>