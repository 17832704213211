import apiClient from "./index";
let get_post = async ({slug_id }) => {
    try {
        const response = await apiClient.get(
            `/blog/get_post/${slug_id}`
        );

        return response.data

    } catch (err) {



        return false
    }

} 
let get_news_single = async ({ slug_id }) => {
    try {
        const response = await apiClient.get(
            `/news/get_post/${slug_id}`
        );

        return response.data

    } catch (err) {



        return false
    }

} 


let get_posts = async (lang = 'en', page = 1, pageSize = 9, category_id = '')=> {
    try {
        let url = `?page=${page}&pageSize=${pageSize}&locale=${lang}`

        if (category_id) {
            url = `?page=${page}&pageSize=${pageSize}&locale=${lang}&category_id=${category_id}`
        }
        const response = await apiClient.get(
            `/blog/get_posts` + url
        );

        return response.data

    } catch (err) {



        return false
    }

}



let get_news = async (lang = 'en', page = 1, pageSize = 9, category_id = '') => {
    try {
        let url = `?page=${page}&pageSize=${pageSize}&locale=${lang}`

        if (category_id) {
            url = `?page=${page}&pageSize=${pageSize}&locale=${lang}&category_id=${category_id}`
        }
        const response = await apiClient.get(
            `/news/get_posts` + url
        );

        return response.data

    } catch (err) {



        return false
    }

}

let blogs = {
    get_posts,
    get_post,
    get_news,
    get_news_single
}
export default blogs;