<template>
    <div class="w-100 bg_gray  position-relative">
        <div class="striped-gradient"></div>
        <div class="container">
            <section class="main-content">

                <!-- <img :class="{ 'img-1-ar': $i18n.locale === 'ar', 'img-1': $i18n.locale !== 'ar' }"
            src="@/assets/images/svg/productVariety-ab.svg" alt="logo" /> -->
                <div class="boxProductVariety">

                    <img class="img" loading="lazy" v-if="$i18n.locale == 'en'" :src="productVarity" alt="">
                    <img class="img-mob" loading="lazy" v-if="$i18n.locale == 'en'" :src="SmallProductVarietyEn" alt="">

                    <img class=" img" loading="lazy" v-if="$i18n.locale == 'ar'" :src="productVarity_ar" alt="">
                    <img class="img-mob" loading="lazy" v-if="$i18n.locale == 'ar'" :src="SmallProductVarietyAr" alt="">


                    <div :class="{ 'text-sec-ar': $i18n.locale === 'ar', 'text-sec': $i18n.locale !== 'ar' }">
                        <!-- <p class="number">10+</p> -->
                        <h2 class="text-huge-custom fw-700" style="color: #0000E2">{{ $t('product.title') }}</h2>
                        <p style="font-size: 24px;" class="text text-start text-xlarge-24">{{ $t('product.desc') }}</p>
                    </div>
                </div>
            </section>
        </div>
    </div>


</template>

<style lang="scss" scoped>
// $column-count: 3;
// $row-count: 4;
// $column-width: 240px; // Adjust as needed

// .img-box {
//     display: grid;
//     grid-template-columns: repeat($column-count, $column-width);
//     grid-template-rows: repeat($row-count);
//     row-gap: 24px;
//     column-rule: 77px;
//     width: 672px;
//     overflow: hidden !important;
//     flex-shrink: 0;



//     svg :hover {
//         transform: scale(1.1);
//         transition: transform 0.3s ease-in-out;
//     }
// }
.striped-gradient{
    background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0), #F8F9FB), radial-gradient(farthest-side at 0% 50%, rgb(255 217 0 / 68%), rgba(0, 0, 255, 0));
}
.bg_gray {
    background-color: #F8F9FB;
    padding: 120px 0px 120px 0px;
    z-index: 0;
    @media screen and (max-width: 980px) {
            padding: 120px 24px 120px 24px;
    
        }
    .striped-gradient{
        top: 0;
        bottom: unset;
    }
}
.img-1 {
    position: absolute;
    top: 0;
    right: 0px;



    @media screen and (max-width: 1200px) {
        display: none;


    }


}

.img-1-ar {
    position: absolute;
    top: 0;
    left: 0px;



    @media screen and (max-width: 1200px) {
        display: none;


    }


}

.main-content {
    position: relative;
   // background-color: #F8F9FB;
    margin: 0;
   
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

   

    .img-mob {
        display: none;

        @media screen and (max-width: 700px) {
            display: block;
            width: 100%;

        }
    }

    .img {
        @media screen and (max-width: 1180px) {
            width: 50% !important;



        }

        @media screen and (max-width: 960px) {
            width: 100% !important;



        }


        @media screen and (max-width: 700px) {
            width: 100% !important;
            display: none;


        }
    }

    .boxProductVariety {
        width: 1464px;
        height: 731px;
        background: #FFFFFF;
        box-shadow: 0px 32px 40px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;

        @media screen and (max-width: 1500px) {
            width: 100%;



        }

        @media screen and (max-width: 980px) {
            width: 100%;
            height: 100%;
            flex-direction: column-reverse;
        }

        .number {
            font-size: 148px;
            line-height: 179px;
            text-align: center;
            color: #AFAFAF;
            padding: 0;
            margin: 0;

            @media screen and (max-width: 980px) {
                font-size: 88px !important;

            }
        }

        h2 {
            padding: 16px 0px 16px 0px;
            margin: 0;

            @media screen and (max-width: 980px) {
                font-size: 32px !important;

            }
        }

        .text-sec {
            padding-left: 56px;
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            @media screen and (max-width: 980px) {
                padding-left: 16px !important;

            }

            .text {
                color: #5D5D5D;

                @media screen and (max-width: 980px) {
                    margin-bottom: 8px;
                    font-size: 16px !important;
                }
            }
        }

        .text-sec-ar {
            padding-left: 56px;
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            @media screen and (max-width: 980px) {
                padding-right: 16px !important;

            }

            .text {
                color: #5D5D5D;

                @media screen and (max-width: 980px) {
                    margin-bottom: 8px;
                    font-size: 16px !important;
                }
            }
        }

    }
}
@media (max-width: $breakpoint-medium) {

    .text-sec-ar,.text-sec,
    h2,
    p {
        text-align: center !important;
        justify-content: center !important;
        align-items: center !important;
    }
}
</style>

<script>
import SmallProductVarietyEn from "@/assets/images/svg/SmallProductVariety-EN.svg";
import productVarity from "@/assets/images/svg/productVarity.svg";
import productVarity_ar from "@/assets/images/svg/productVarity-ar.svg";
 
import SmallProductVarietyAr from "@/assets/images/svg/SmallProductVariety-AR.svg";

export default {
    name: "ProductVariety",
    data() {
        return {
            SmallProductVarietyEn,
            productVarity,
          
            SmallProductVarietyAr,
            productVarity_ar
        }
    },
};
</script>