<template>
    <section class="relative">
        <div class="bg-image-1">

        </div>
        <div class="card-section">

            <h2 class="fw-700 text-xxlarge res" style="color: #0000E2">{{ $t('our_services.title') }}</h2>
            <div class="cardSectionMain">

                <div class="cardBox">
                    <div class="text-area">
                        <div class="svgBox m-auto">
                            <img :src="svg_2" loading="lazy" alt="">


                        </div>
                        <h3 class="font-24 fw-500" style="color: #0000E2">{{ $t("our_services.card.title2") }}</h3>
                        <p class="font-18">{{ $t("our_services.card.desc2") }}</p>
                    </div>

                    <router-link to="" aria-label="more" class="w-100">
                        <button class="link pallet">

                            {{ $t("More") }}

                        </button>
                    </router-link>

                </div>

                <div class="cardBox">
                    <div class="text-area">
                        <div class="svgBox m-auto">
                            <img :src="pallet_image" loading="lazy" alt="">




                        </div>
                        <h3 class="font-24 fw-500" style="color: #0000E2">{{ $t("our_services.card.title1") }}</h3>
                        <p class="font-18">{{ $t("our_services.card.desc") }}</p>
                    </div>
                    <router-link to="/pallet-storage" aria-label="more" class="w-100">
                        <button class="link trans">

                            {{ $t("More") }}

                        </button>
                    </router-link>


                </div>


                <div class="cardBox">
                    <div class="text-area">
                        <div class="svgBox m-auto">
                            <img :src="svg_3" loading="lazy" alt="">

                        </div>
                        <h3 class="font-24 fw-500" style="color: #0000E2">{{ $t("our_services.card.title3") }}</h3>
                        <p class="font-18">{{ $t("our_services.card.desc3") }}</p>
                    </div>

                    <router-link to="/transportation" aria-label="more" class="w-100">
                        <button class="link sqm">

                            {{ $t("More") }}

                        </button>
                    </router-link>

                </div>
            </div>



        </div>
        <div class="container py-4 z-1 pb-3">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="fw-700 text-xxlarge res" style="color: #0000E2">{{ $t('SaidAbout') }}</h2>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.relative{
    position: relative;
    padding: 80px 16px 0px 16px;
}
.z-1{
    z-index: 1;
}
 .bg-image-1 {
    
     background-size: cover;
     position: relative;
     overflow: hidden;
     position: absolute;

     overflow: hidden;
     background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0), rgb(255,
        255,
        255)), radial-gradient(farthest-side at 0% 50%, rgba(0, 0, 255, 0.68), rgba(0, 0, 255, 0));
     background-size: auto;
     /* Keep original background size */
     background-position: right;
     /* Align background to the right */
     //width: 300px;
     //height: 300px;
     //transform: translate(-255%, -18%)
     overflow: hidden;
     left: 0;
     top: 0;

    width: 447px;
    height: 100%;
    z-index: 0;
    opacity: 0.7;
     z-index: 0;
 }
.link {
    width: 100%;
    a{
        text-decoration: none;
        color: #000;
    
    }
}



.res {
    @media screen and (max-width: 1100px) {
        font-size: 32px;
    }
        z-index: 2;
}
 
section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fff;
    padding: 80px 0px 0px 0px;

    @media screen and (max-width: 1464px) {

        padding: 160px 80px 80px 80px;
    }

    @media screen and (max-width: 1100px) {
        padding: 160px 24px 80px 24px;


    }

    @media screen and (max-width: 600px) {
        padding: 80px 16px 80px 16px;


    }

    .card-section {
        width: 1464px;
        z-index: 2;
        
        cursor: pointer;

        @media screen and (max-width: 1464px) {
            width: 100%;


        }
    }

    .cardSectionMain {
        min-height:513px;// 450px;
        border: 0;
        background-color: transparent !important;
        gap: 15px;
        display: flex;

        justify-content: space-between;
        margin-top: 32px;

        @media screen and (max-width: 1100px) {
            gap: 15px;


        }

        @media screen and (max-width: 600px) {
            flex-direction: column;
            gap: 16px;




        }
    }

    .cardBox {
        z-index: 2;
        width: 456px;
        padding: 32px;
        min-height: 280px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: inherit;
        transition: all 1s ease;
        .svgBox {
        
                width: 100px;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #F5F5F5;
                border-radius: 4px;
                img{
                    transition: all 1s ease;
                }
        
            }
      

        .text-area {
           // transition: all 1s ease;
            display: flex;
            flex-direction: column;
            gap: 50px;
            img {
                max-width: 148px;
                max-height: 148px;
            }

            h2 {
                padding: 16px 0px;
                margin: 0;
                
                @media screen and (max-width: 1100px) {
                    font-size: 24px;


                }

            }

            p {
                color: #5D5D5D;

                @media screen and (max-width: 1100px) {
                    font-size: 18px;


                }
            }
        }

        .font-18{
            display: none;
            @media screen and (max-width: 1100px) {
                  
                 display: block;
            
                }
        }

        button {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #363636;
            background: #FFEC00;
            border-radius: 8px;
            border: none;
            padding: 16px;
            width: 100%;
            display: none;

            @media screen and (max-width: 1100px) {
                display: block;
                padding: 8px;
                margin-top: 20px;


            }

        }

        @media screen and (max-width: 1464px) {
            width: 394px;
        }

        @media screen and (max-width: 1100px) {
            width: 240px;
            height: auto;
            padding: 16px;
            box-shadow: 0px 32px 40px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            transition: unset;

        }
          
            .font-18 {
                display: block;
            }
            .text-area {
                gap: 12px;

                img {
                    width: 90px;
                    height: 90px;

                }

                h2,
                h3 {
                    padding: 10px 0px;
                    font-size: 24px;
                }

                p {
                    font-size: 18px;
                }
            }
        @media screen and (min-width: 1101px) {
            &:hover {
               

                box-shadow: 0px 32px 40px rgba(0, 0, 0, 0.1);
                    border-radius: 8px;

                button {
                    display: block;
                }

               
            }
        }

        @media screen and (max-width: 600px) {
            width: 100%;
            height: auto;
            padding: 16px;
        .text-area {
            img{
                width: 100px;
                height: 100px;
            }
        }

        }
    }
}

 

@media (max-width: $breakpoint-medium) {
  
    .bg-image-1,
    .striped-gradient {
        width: 324px;
        height: 328px;
    }

    
}

@media (max-width: $breakpoint-small) {
  
    .bg-image-1,
    .striped-gradient {
        width: 224px;
        height: 228px;
    }

   
}
</style>

<script>
import pallet_image from "@/assets/images/svg/pallet_image.svg";
import svg_2 from "@/assets/images/svg/svg_2.svg";
import svg_3 from "@/assets/images/svg/svg_3.svg";
export default {

    layout: 'website_v3_layout',
    name: "OurServices",
    data() {
        return {
            pallet_image,
            svg_2,
            svg_3
        }
    },
   

};
</script>