<template>
    <section class="section2_solution">
        <div class="questionSection">
            <h3 class="question">{{ $t('solution.Question1') }}</h3>
            <p class="answer">{{ $t('solution.answer1') }}</p>
            <h3 class="question">{{ $t('solution.Question1_2') }}</h3>
            <p class="answer">{{ $t('solution.answer1_2') }}</p>
            <h3 class="question">{{ $t('solution.Question2') }}</h3>
            <p class="answer">{{ $t('solution.answer2') }}</p>

        </div>
        <!-- <img src="~/assets/images/solution.svg" class="img-solution" /> -->
    </section>
</template>

<script>
export default {
    name:"SEOSection"
}
</script>

<style lang="scss">
.section2_solution {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    background: #F8F9FB;
    padding: 60px 0px;
    visibility: hidden;
        height: 0;
        padding: 0;
    @media screen and (max-width: 1450px) {

        padding: 0px 40px;


    }

    @media screen and (max-width: 1100px) {

        flex-direction: column-reverse;


    }

    @media screen and (max-width: 900px) {

        padding: 0px 16px;


    }


}
</style>