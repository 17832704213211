<template>

    <section class="main-container m-3">
        <h2 class="text-xlarge  color-primary fw-700">
            {{ $t("storage_options" + ".title") }}
        </h2>
        <p class="font-18">{{ $t("storage_options" + ".description") }}</p>
        <div class="gridHowItWorks">
            <div class="gridHowItWorks-card" v-for="(option, i) in options" :key="i">
                <div class="img-div">
                    <img :src=" option.image" loading="lazy"
                        :alt="$t('storage_options' + '.option' + (i + 1) + '.name').toString()" />
                </div>
                <h3 style="margin: 15px 0px;" class="text-base fw-600">
                    {{ $t("storage_options" + ".option" + (i + 1) + ".name") }}
                </h3>
                <p class="color-desc text-small-mid">{{ $t("storage_options" + ".option" + (i + 1) + ".description") }}
                </p>
            </div>
        </div>
        <!-- <router-link to="/dashboard/rfq">
            <CommonButton "storage_options"="store_with_us" />
        </router-link> -->
        <!-- <a class="btn btn-primary" :href="`${BASE_FRONT_URL}` + `/${$i18n.locale}` + '/dashboard/rfq'">
            {{ $t("storage_options.AskQoutation") }}
        </a> -->
        <!-- <button class="btn btn-primary">
            {{ $t("storage_options.AskQoutation") }}
        </button> -->
    </section>
</template>

<style lang="scss" scoped>
.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 0px;

    h2 {
        background-color: transparent !important;
        padding: 0px;
        margin: 0px;

        @media screen and (max-width: 800px) {
            text-align: center;
        }
    }

    p {
        color: #5d5d5d;
        background-color: transparent !important;
        width: 943px;
        text-align: center;
        margin: 32px 0px 0px;
        padding: 0px;

        @media screen and (max-width: 1024px) {
            width: 500px;
        }

        @media screen and (max-width: 500px) {
            width: 100%;
            padding: 0px 24px;
        }
    }

    .gridHowItWorks {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        margin: 32px 0px 32px 0px;
        padding: 0px;

        @media screen and (max-width: 1024px) {
            flex-wrap: wrap;
            justify-content: center;
        }
    }


    .gridHowItWorks-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 256px;
        padding: 20px;

        @media screen and (max-width: 500px) {
            width: 100%;
        }

        .img-div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 100px;
            background: #ffffff;
            box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
            border-radius: 14px;
            padding: 8px;
            margin: 0px;
        }

        h3 {
            color: #363636;
            background-color: transparent !important;
            padding: 0;
            margin: 12px 0px;
        }

        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            text-align: center;
            color: #5d5d5d;
            padding: 0;
            margin: 0;
            width: 100%;
        }
    }
}

@media (max-width: $breakpoint-small) {
    .btn-primary {
        // display: flex;
        // flex-direction: column;
        // gap: 15px;
        width: 100%;

        // span {
        //     display: none;
        // }
    }

}
.m-3{
    margin: 0 12px;
}
</style>

<script >
import Ambient from "@/assets/images/svg/normal.svg";
import Chilled from "@/assets/images/svg/cold.svg";
import Dry from "@/assets/images/svg/dry.svg";
import Frozen from "@/assets/images/svg/frozen.svg";
import { env } from "@/static/env";
export default{
    name: "TypesOfStorage",
    computed: {
        refId() {
            return this.$route.query.ref;
        }
    },
    data: () => ({
        options: [
            { image: Dry },
            { image: Ambient },
            { image: Chilled },
          
            { image: Frozen },
        ],
        BASE_FRONT_URL: env.BASE_FRONT_URL
    }),
};
</script>
