import { createI18n } from 'vue-i18n';
import en from './locales/en.js'; // Adjust path as necessary
import ar from './locales/ar.js'; // Adjust path as necessary

const i18n = createI18n({
    locale: 'ar', // set default locale
    fallbackLocale: 'ar', // set fallback locale
    messages: { en, ar },
});

export { i18n };
