export const navItemsNew = [
  {
    id: 1,
    translationKey: "header.nav_links_v3.about_us",
    router: "/about-us",
    
  },
  {
    id: 2,
    translationKey: "header.nav_links_v3.services",
    subItems: [
      {
        translationKey: "header.nav_links_v3.transportation",
        router: "/transportation",
        descriptionKey: "header.nav_links_v3.transportation_description",
        svg:'transport'
      },
      {
        translationKey: "header.nav_links_v3.pallet_storage",
        router: "/pallet-storage",
        descriptionKey: "header.nav_links_v3.pallet_storage_description",
        svg:'pallet'
      },
      // {
      //   translationKey: "header.nav_links_v3.sqm_storage",
      //   router: "/sqm-storage",
      //   descriptionKey: "header.nav_links_v3.sqm_storage_description",
      //   svg:'sqm'
      // },
    ],
    showDropdown: false,
  },
  {
    id: 3,
    translationKey: "header.nav_links_v3.how_it_works",
    router: "/how-it-works",
    
  },
  {
    id: 4,
    translationKey: "header.nav_links_v3.resources",
    subItems: [
      {
        translationKey: "header.nav_links_v3.blog",
        router: "/blog",
        descriptionKey: "header.nav_links_v3.blog_description",
        svg:'blog'
      },
      {
        translationKey: "header.nav_links_v3.news",
        router: "/news",
        descriptionKey: "header.nav_links_v3.news_description",
        svg:'news'
      },
    ],
    showDropdown: false,
  },
];

export const navItems = [
  { translationKey: "header.nav_links.home", router: "/" },
  { translationKey: "header.nav_links.about_us", router: "/about-us" },
  { translationKey: "header.nav_links.services", router: "/services" },
  { translationKey: "header.nav_links.how_it_works", router: "/how-it-works" },
  { translationKey: "header.nav_links.contact_us", router: "/contact-us" },
  { translationKey: "header.nav_links.blog", router: "/blog" },
];

export const footerNavItems = [
  { translationKey: "header.nav_links.home", router: "/" },
  { translationKey: "header.nav_links.about_us", router: "/about-us" },
  { translationKey: "header.nav_links.services", router: "/services" },
  { translationKey: "header.nav_links.how_it_works", router: "/how-it-works" },
  { translationKey: "header.nav_links.faq", router: "/faq" },
  { translationKey: "header.nav_links.blog", router: "/blog" },
 // { translationKey: "header.nav_links.careers", router: "/careers" },
  { translationKey: "header.nav_links.contact_us", router: "/contact-us" },
];

export const howItWorksCombactSteps = [
  {
    image: "request.webp",
    nameKey: ".request_quotation_name",
    descriptionKey: ".request_quotation_description",
  },
  {
    image: "approve.webp",
    nameKey: ".approve_quotation_name",
    descriptionKey: ".approve_quotation_description",
  },
  {
    image: "ship.webp",
    nameKey: ".ship_quotation_name",
    descriptionKey: ".ship_quotation_description",
  },
  {
    image: "store.webp",
    nameKey: ".store_quotation_name",
    descriptionKey: ".store_quotation_description",
  },
  {
    image: "pick.webp",
    nameKey: ".pick_quotation_name",
    descriptionKey: ".pick_quotation_description",
  },
];

export const team = [
  {
    imageFile: "01.jpg",
    nameKey: "husam_sendi",
    position: "CEO",
    linkedinLink: "https://www.linkedin.com/in/husamsendi/",
  },
  {
    imageFile: "2.jpg",
    nameKey: "husam_sabano",
    position: "Co-founder & CTO",
    linkedinLink: "https://www.linkedin.com/in/husam-sabano-06a6621b7/",
  },
  // {
  //   imageFile: "30.png",
  //   nameKey: "Khalid_Nagadi",
  //   position: "Co-founder",
  // },
  {
    imageFile: "20.jpg",
    nameKey: "hasan_mustafa",
    position: "Full-Stack Development Lead",
    linkedinLink: "https://www.linkedin.com/in/hassan-mh/",
  },
  {
    imageFile: "3.jpg",
    nameKey: "noha_alhasan",
    position: "Communications Manager",
    linkedinLink: "https://www.linkedin.com/in/nohanalhasan/",
  },
  // {
  //   imageFile: "4.jpg",
  //   nameKey: "Marah_Jarad",
  //   position: "Marketing Specialist",
  //   linkedinLink: "",
  // },
  // {
  //   imageFile: "5.jpg",
  //   nameKey: "Nouman_Farooq",
  //   position: "Marketing Specialist",
  //   linkedinLink: "",
  // },
  {
    imageFile: "6.jpg",
    nameKey: "aalian_ansari",
    position: "Sales Supervisor",
    linkedinLink: "https://www.linkedin.com/in/aaliyan-ansari-7a3ab3234/",
  },
  // {
  //   imageFile: "7.jpg",
  //   nameKey: "abdulkareem_alasami",
  //   position: "Sales Supervisor",
  //   linkedinLink: "https://www.linkedin.com/in/abdulkarim-al-asami-027358235/",
  // },
  {
    imageFile: "8.jpg",
    nameKey: "Majed_Alqathmi",
    position: "Senior Sales Specialist",
    linkedinLink: "",
  },
  // {
  //   imageFile: "9.jpg",
  //   nameKey: "Azharuddin_Mohammed",
  //   position: "Sales Specialist",
  //   linkedinLink: "",
  // },
  // {
  //   imageFile: "11.jpg",
  //   nameKey: "Waleed_Atiah",
  //   position: "Sales Specialist",
  //   linkedinLink: "",
  // },
  // {
  //   imageFile: "12.jpg",
  //   nameKey: "Saad_Almuqrin",
  //   position: "Sales Specialist",
  //   linkedinLink: "",
  // },
  // {
  //   imageFile: "13.jpg",
  //   nameKey: "Faisal_Altamehi",
  //   position: "Operations Supervisor",
  //   linkedinLink: "https://www.linkedin.com/in/faisal-altamehi-b279091b4",
  // },
  // {
  //   imageFile: "14.jpg",
  //   nameKey: "Hisham_Alamoudi",
  //   position: "Supplier Relations Supervisor",
  //   linkedinLink: "https://www.linkedin.com/in/hisham-alamoudi-b911a4125/",
  // },
  {
    imageFile: "15.jpg",
    nameKey: "Ahmed_Qamar",
    position: "Supplier Relations Specialist",
    linkedinLink: "https://www.linkedin.com/in/ahmad-qamar/",
  },
  // {
  //   imageFile: "16.jpg",
  //   nameKey: "Taif_Abdulbagi",
  //   position: "Supplier Relations Specialist",
  //   linkedinLink: "",
  // },
  {
    imageFile: "17.jpg",
    nameKey: "Duaa_Thaiban",
    position: "Customer Relations Specialist",
    linkedinLink: "",
    noImage: true,
  },

  // {
  //   imageFile: "18.jpg",
  //   nameKey: "Saleh_Bahashwan",
  //   position: "Customer Relations Specialist",
  //   linkedinLink: "",
  // },
  // {
  //   imageFile: "alaa_mansour.jpg",
  //   nameKey: "alaa_mansour",
  //   position: "UI/UX Designer",
  //   linkedinLink: "https://www.linkedin.com/in/alaa-mansour-rabia/",
  // },

  {
    imageFile: "21.jpg",
    nameKey: "areej_amin",
    position: "Back-End Developer",
    linkedinLink: "https://www.linkedin.com/in/areej-ameen-301190105/",
    noImage: true,
  },
  {
    imageFile: "26.jpeg",
    nameKey: "Eslam_Dahshan",
    position: "Front-End Developer",
    linkedinLink: "https://www.linkedin.com/in/eslam-dahshan/",
  },
  // {
  //   imageFile: "taha.png",
  //   nameKey: "Taha_Osama_Aziz",
  //   position: "Front-End Developer",
  //   linkedinLink: "https://www.linkedin.com/in/taha-osama-23b7931b7",
  // },
  {
    imageFile: "omar.jpg",
    nameKey: "Omar_Mohammed",
    position: "Quality Assurance Tester",
  },
  {
    imageFile: "faris.png",
    nameKey: "Faris_Almaharasi",
    position: "Customer Experience Specialist",
  },
  // {
  //   imageFile: "22.jpg",
  //   nameKey: "Mohammed_Alamoudi",
  //   position: "Finance Manager",
  //   linkedinLink: "",
  // },
  {
    imageFile: "23.jpg",
    nameKey: "Abdullah_Bokhari",
    position: "Legal Affairs Manager",
    linkedinLink: "",
  },
  // {
  //   imageFile: "24.jpg",
  //   nameKey: "Raneem_Alghamdi",
  //   position: "HR Specialist",
  //   linkedinLink: "",
  //   noImage: true,
  // },
  // {
  //   imageFile: "25.jpg",
  //   nameKey: "Duaa_Sulaimani",
  //   position: "Performance Specialist",
  //   linkedinLink: "",
  // },

  // {
  //   imageFile: "25.jpg",
  //   nameKey: "Baraah_Halawani",
  //   position: "Administrative Assistant",
  //   linkedinLink: "",
  //   noImage: true,
  // },
  // {
  //   imageFile: "27.png",
  //   nameKey: "Jory_Majdi_Waddu",
  //   position: "Financal Accountant",
  //   linkedinLink: "",
  //   noImage: false,
  // },
  // {
  //   imageFile: "rawas.png",
  //   nameKey: "Mohammed_Rawas",
  //   position: "Customer Experience Lead",
  //   linkedinLink: "",
  //   noImage: false,
  // },
  {
    imageFile: "33.png",
    nameKey: "Hassan_Jamal",
    position: "Customer Experience Specialist",
    linkedinLink: "",
    noImage: false,
  },
  /* 
  {imageFile: 'areej_amin.jpg', nameKey: 'areej_amin', position: 'Back-End Developer', linkedinLink: 'https://www.linkedin.com/in/areej-ameen-301190105/', noImage: true},
  {imageFile: 'abdurahman_suliman.jpg', nameKey: 'abdurahman_suliman', position: 'Scrum Master', linkedinLink: 'https://www.linkedin.com/in/abdelrahman-soliman-98672623a'},
  {imageFile: 'ahmed_atif.jpg', nameKey: 'ahmed_atif', position: 'RPA Developer', linkedinLink: 'www.linkedin.com/in/ahmed-atef-ab4907111'},
  {imageFile: 'hisham_alamoudi.jpg', nameKey: 'hisham_alamoudi', position: 'Operations Officer', linkedinLink: 'https://www.linkedin.com/in/hisham-alamoudi-b911a4125/'},
 
  {imageFile: 'alaa_abualhasan.jpg', nameKey: 'alaa_abualhasan', position: 'Sales Manager', linkedinLink: 'https://www.linkedin.com/in/alaa-abu-alhassan-0724bba5/'},
  {imageFile: 'alaa_mansour.jpg', nameKey: 'alaa_mansour', position: 'UI/UX', linkedinLink: 'https://www.linkedin.com/in/alaa-mansour-rabia/'},
  {imageFile: 'abdulkareem_alasami.jpg', nameKey: 'abdulkareem_alasami', position: 'Sales Representative', linkedinLink: 'https://www.linkedin.com/in/abdulkarim-al-asami-027358235/'},
   
  {imageFile: 'aalian_ansari.jpg', nameKey: 'aalian_ansari', position: 'Sales Representative', linkedinLink: 'https://www.linkedin.com/in/aaliyan-ansari-7a3ab3234/'},
  {imageFile: 'abdullah_alsharif.jpg', nameKey: 'abdullah_alsharif', position: 'Sales Operations', linkedinLink: ''},
  {imageFile: 'numan_ali.jpg', nameKey: 'numan_ali', position: 'Sales & Marketing Specialist', linkedinLink: 'https://www.linkedin.com/in/nouman-farooq-677921194/'},
  
  {imageFile: 'ahmed_qamaralam.jpg', nameKey: 'ahmed_qamaralam', position: 'Operations Officer', linkedinLink: 'https://www.linkedin.com/in/ahmad-qamar/'},
  {imageFile: 'faisal_altamehi.jpg', nameKey: 'faisal_altamehi', position: 'Operations Supervisor', linkedinLink: 'https://www.linkedin.com/in/faisal-altamehi-b279091b4'},
  {imageFile: 'yusuf_alhabeeb.jpg', nameKey: 'yusuf_alhabeeb', position: 'Business Developer', linkedinLink: 'https://www.linkedin.com/in/yusuf-fahad-alhabeeb-2883a31b5/'},
  {imageFile: 'abdullah_bokhari.jpg', nameKey: 'abdullah_bokhari', position: 'Business Developer', linkedinLink: 'https://www.linkedin.com/in/abdullah-bokhari-a8243322b'},
  {imageFile: 'wdad_alharbi.jpg', nameKey: 'wdad_alharbi', position: 'Sales Representative', linkedinLink: 'https://linkedin.com/in/muhammed-ibrahim', noImage: true}, */
];

export const jobLocations = [
  { id: 1, cityKey: "jeddah" },
  { id: 2, cityKey: "riyadh" },
];

export const jobDepartments = [
  { id: 1, departmentKey: "sales", onSite: false },
  { id: 2, departmentKey: "finance", onSite: true },
  { id: 3, departmentKey: "quality_assurance", onSite: true },
  { id: 4, departmentKey: "data", onSite: false },
  { id: 5, departmentKey: "marketing", onSite: true },
];

export const jobs = [
  {
    id: 1,
    jobTitle: "Senior Sales Manager",
    departmentKey: "sales",
    locationKey: "on_site",
  },
  {
    id: 2,
    jobTitle: "Accountant",
    departmentKey: "finance",
    locationKey: "remote",
  },
  {
    id: 3,
    jobTitle: "Senior Sales Manager",
    departmentKey: "data",
    locationKey: "on_site",
  },
  {
    id: 4,
    jobTitle: "Senior Sales Manager",
    departmentKey: "marketing",
    locationKey: "on_site",
  },
  {
    id: 5,
    jobTitle: "Accountant",
    departmentKey: "quality_assurance",
    locationKey: "on_site",
  },
  {
    id: 6,
    jobTitle: "Senior Sales Manager",
    departmentKey: "sales",
    locationKey: "remote",
  },
];

export const servicesSpecs = {
  transportation: {
    imageTo: "flex-start",
    imageFile: "services-transportation.webp",
    router: "/how-it-works",
  },
  pallets: {
    imageTo: "flex-end",
    imageFile: "services-pallets.webp",
    router: "/how-it-works",
  },
};

export const facilities = [
  { id: 1, wildcard: "staff" },
  { id: 2, wildcard: "assurance" },
  { id: 3, wildcard: "security" },
  { id: 4, wildcard: "proximity" },
  { id: 5, wildcard: "spaces" },
  { id: 6, wildcard: "appliances" },
  { id: 7, wildcard: "design" },
  { id: 8, wildcard: "cs" },
];

export const testimonials = [
  {
    id: 1,
    image: "NSC.png",
    subject:
      "افضل شركة تخزين. فروعهم في جميع مناطق المملكة و عندهم شاحنات مجهزة و معدات حديثة متطورة في النقل و التخزين و سعرهم الأفضل بين المنافسين. خدماتهم سريعة و الأفضل.",
    name: "ريان الغامدي",
    position: "NSC",
  },
  {
    id: 2,
    image: "Loud_Logo.png",
    subject:
      "I was struggling to find a place to store my company's equipment for some time, then I stumbled upon Logexa and it offered a quick and easy solution for me. They picked up my equipment and stored it in their warehouse for me. It was such a hassle free service with a very affordable price.",
    name: "Rayan Fadul",
    position: "Loud Co-Founder",
  },
  {
    id: 3,
    image: "White_Space.png",
    subject: "لوجيكسا سريعين في التعامل. هويتهم متميزة و السعر جدا مناسب",
    name: "عبدالرحيم سمير",
    position: "White SPACE",
  },
  {
    id: 4,
    image: "jusoorhub.png",
    summary: "شركاء النجاح في لوجيكسا",
    subject:
      "من خلال حلولهم المرنة للتخزين، سهلوا علينا تقديم خدمة متكاملة للمتاجر الالكترونية",
    name: "عمر فال",
    position: "Jusoorhub",
  },
  {
    id: 5,
    image: "ok_مؤسسة_خالد_كعبي.png",
    subject:
      "وشكر خاص الى شركة حلول التخزين المرنة ونشكركم على حسن تعاملكم معنا والإلتزام بتسليم الطلبات وفي الوقت المناسب أيضا لديكم خبرة كبيرة في التعامل معنا ومع عملائكم. بالتوفيق الدائم.",
    name: "خالد الكعبي",
    position: "مؤسسة خالد علي حسن كعبي للتجارة",
  },
  {
    id: 6,
    image: "emaar.png",
    summary:
      "عندما تبدع الأيادي المبادرة و العقول الراقية تكون النتائج عظيمة بأقل تكلفة ممكنة",
    subject:
      "تتقدم مؤسسة قصر الإعمار للتجارة بجزيل الشكر والتقدير إلى جميع العاملين والإدارين في لوجيكسا لتقديمهم الحلول المبتكرة والخدمة المتميزة في نقل وتخزين البضائع داخل المملكة",
    name: "حسام اسلام",
    position: "مؤسسة قصر الإعمار للتجارة",
  },
  {
    id: 7,
    image: "reesha.png",
    subject:
      "نود أن نشكر لوجيكسا على كل ما تقدمه من خدمات فريدة من نوعها و سريعة للغاية . بجانب ذلك نشكرهم على دعمهم المستمر و الدائم لنا ... و يسعدنا أن نكون ضمن عملائهم ...",
    name: "خالد سالم",
    position: "مؤسسة الريشة للمواد الغذائية",
  },
  {
    id: 8,
    image: "reema.png",
    subject:
      "عنوان جودة الاداء و الاحترافيه و العقول المبتكرة، تتقدم نحن شركة ربما المحدودة الى العقول المبدعه المبتكرة بجزيل الشكر لفريق عمل لوجيكسا مع امنياتي بدوام التقدم",
    name: "عبدالفتاح العطار",
    position: "شركة ربما المحدودة",
  },
  {
    id: 9,
    image: "Cactus_House.png",
    subject: "احترافية في التخزين و كل شيء منظم و مدقق و موثق",
    name: "سلطان الجزار",
    position: "بيت الصبَّار",
  },
  {
    id: 10,
    image: "soil.png",
    subject:
      "لوجيكسا من الشركات الرائدة فى ادارة المخزون فمعها تم ادارة المخزون بافضل طريقه ممكنه وباقل تكلفه فى السوق.",
    name: "احمد جمال",
    position: "Soil",
  },
  {
    id: 11,
    image: "alphainvestment.png",
    subject:
      "لوجيكسا من افضل الشركات التى تعاملنا معها فى الخدمات اللوجستية اذا لم تكن الاولى عليهم, وجدنا معها النظام والاهتمام والتعاون والخدمه على مدار 24 ساعه وكاننا العميل الوحيد معهم. وهذا يدل على حسن الادراه والفريق الذى يعمل بقلب رجل واحد",
    name: "عمر الغامدى",
    position: "Alpha Investment Company",
  },
];

export const stats = [
  {
    id: 1,
    number: "280+",
    titleKey: "about_us.stats.title1",
    descriptionKey: "about_us.stats.desc1",
  },
  {
    id: 2,
    number: "1k+",
    titleKey: "about_us.stats.title2",
    descriptionKey: "about_us.stats.desc2",
  },
  {
    id: 3,
    number: "8+",
    titleKey: "about_us.stats.title3",
    descriptionKey: "about_us.stats.desc3",
  },
  {
    id: 4,
    number: "80k+",
    titleKey: "about_us.stats.title4",
    descriptionKey: "about_us.stats.desc4",
  },
];
