<template>
    <div class="w-100  position-relative">
        <div class="striped-gradient"></div>

        <div class="container  py-4">

            <div class="row">

                <div class="col-md-5  text-start p-4 d-flex justify-content-center flex-column main">
                    <h3 class="fw-700 text-xxlarge color-primary mb-4">
                        {{ $t('Hero.Logexa') }}
                    </h3>

                    <p class="fw-400 text-small color-desc color-black-main text-small-mid">
                        {{ $t('Hero.whyLogexa_desc') }}
                    </p>

                    <div class="group">
                        <router-link to="/about-us" class="btn btn-warning text-very-small fw-500 "> {{
                            $t("our_services.card.learnMore") }}</router-link>
                        <!-- <button class="btn btn-warning text-very-small fw-500 ">
                            {{ $t("our_services.card.learnMore") }}
                        </button> -->
                        <span>
                            &nbsp; &nbsp;
                        </span>
                        <a class="btn btn-primary text-very-small fw-500 "
                            :href="`${BASE_FRONT_URL}` + `/${$i18n.locale}` + '/dashboard/rfq' + `${refId ? '?ref=' + refId : ''}`">
                            {{ $t('Hero.askQoutation') }}
                        </a>
                        <!-- <button class="btn btn-primary text-very-small fw-500 ">
                            {{ $t('Hero.askQoutation') }}
                        </button> -->
                    </div>
                </div>
                <div class="col-md-7">
                    <img @click="showVedioToggle()" loading="lazy" class="promoImage" v-if="!showVedio"
                        :src="vedio_image_frame" alt="vedio_image_frame">
                    <PromoVedio v-if="showVedio"></PromoVedio>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vedio_image_frame from '@/assets/images/main/vedio_image_frame.avif'
import PromoVedio from './PromoVedio.vue'
import { env } from "@/static/env";
export default {
    name: "WhyLogexa",
    components: {
        PromoVedio
    },
    data: function () {
        return {
            vedio_image_frame: vedio_image_frame,
            showVedio: false,
            BASE_FRONT_URL: env.BASE_FRONT_URL
        };
    },
    computed: {
        refId() {
            return this.$route.query.ref;
        }
    },
    methods: {
        showVedioToggle() {
            this.showVedio = true;
        },
    },
}
</script>

<style scoped lang="scss">
.promoImage{
    width: 100%;
}
.btn{
  
    font-size: 14px !important;
    font-weight: 600px;
}

@media (max-width: $breakpoint-medium) {
.main,
    h1,
    p {
        text-align: center !important;
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: $breakpoint-small)  {
    .group{
        display: flex;
        flex-direction: column;
        gap:15px;
        width: 100%;
        span{
            display: none;
        }
    }
    
}
</style>