 <template>

    <div class="container-fluid container-fluid_bg my-5 mt-0">
        <div class="row">
            <div class="col-md-12">
                <div class="slide-container" @mouseenter="stopSlideShow" @mouseleave="startSlideShow">
                    <div class="card" v-for="(card, index) in cards" :class="{ 'middle-card': index === 1, 'leaving': card.leaving }"
                        :key="index">

                        <div class="card-body w-100 p-4">
                            <div class="container-fluid">
                                <div class="row mb-4">
                                    <div class="col-md-12 userInfo text-start">
                                        <img loading="lazy"  :src="require('@/assets/images/testimonials2/' + card.image)" alt="Khalid Al-Kaaby" class="rounded-circle"
                                            style="width: 64px; height: 64px;">
                                        <div>
                                            <h5 class="card-title text-start fw-700 text-mid"> {{ card.name }}</h5>
                                            <h6 class="card-subtitle mb-2 text-muted text-start fw-400 text-small-mid">{{ card.position }}
                                            </h6>
                                        </div>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-md-12">

                                        <blockquote cite="source" class="card-text text-center color-desc w-100 animated-text color-desc fw-400 text-small-mid">
                                            {{trimStringTo60Characters(card.subject)  }}
                                        </blockquote>
                                    </div>
                                </div>

                            </div>
                            <!-- <div class="d-flex">
                                <div class="me-3">
                                    <img :src="card.logo" alt="Khalid Al-Kaaby" class="rounded-circle"
                                        style="width: 64px; height: 64px;">
                                </div>
                                <div>
                                    <h5 class="card-title text-start"> {{ card.name }}</h5>
                                    <h6 class="card-subtitle mb-2 text-muted text-start">{{ card.position }}</h6>
                                    <p class="card-text text-center  w-100">
                                        {{ card.said }}
                                    </p>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { testimonials } from "@/store/data";
export default {
    setup() {
        const cards = ref(testimonials); // Initialize cards

        let interval = null;

        function rotateCards() {
             cards.value[0].leaving = true; // Mark the first card as leaving
            //cards.value.push(cards.value.shift()); // Move the first card to the end
             setTimeout(() => {
                cards.value[0].leaving = false; // Reset the leaving property after the transition
                cards.value.push(cards.value.shift()); // Move the first card to the end
            }, 500); // This should match the transition duration
        }

        function startSlideShow() {
            interval = setInterval(rotateCards, 1500); // Adjust time for card movement speed
        }

        function stopSlideShow() {
            clearInterval(interval);
        }

        onMounted(() => {
            startSlideShow();
        });

        onUnmounted(() => {
            stopSlideShow();
        });

        return {
            cards,
            startSlideShow,
            stopSlideShow,
        };
    },
    methods: {
         trimStringTo60Characters(str) {
                if (str.length > 170) {
                    return str.substring(0, 170) + "...";
                } else {
                    return str;
                }
            }
    },
};
</script>

<style scoped lang="scss">
.animated-text {
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
.container-fluid_bg{
    background-color: #F8F9FB;
    .boxShadow{
        box-shadow: 0px 32px 40px 0px #0000001A;
    }
}
.slide-container {
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    margin: auto;
    padding: 30px 0;
    position: relative;
    cursor: pointer;
  
}

 .slide-container::after,
 .slide-container::before {
     content: '';
     position: absolute;
     top: 0;
     bottom: 0;
     width: 20%;
     /* Width of the fade effect */
     z-index: 2;
     pointer-events: none;
     cursor: unset;
     /* Allows clicking on cards beneath */
 }

 .slide-container::before {
     left: 0;
     background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
 }

 .slide-container::after {
     right: 0;
     background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
 }
  
.card {
    flex: 0 0 50%;
    margin-right: 20px;
    //transition: transform 0.5s ease-in-out;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffff;
   // box-shadow: 10px 10px 10px 10px #0000001A;
    box-shadow: 0px 32px 40px 0px #0000001A;

    border-radius: 8px;
    border: 0 !important;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

    .userInfo{
        display: flex;
        flex-direction: row;
        gap: 11px;
        justify-content: flex-start;
        align-items: center;
    }
}
.card.leaving {
     transform: translateX(-100%) scale(0.9);
    opacity: 0;
}
.middle-card {
    z-index: 10; // Ensures the middle card stays on top during transition
    transform: translateX(0%) scale(1.1); // Slightly enlarge and center the middle card
}
.middle-card{
    flex: 0 0 50%;
}
@media (max-width: $breakpoint-small) {
  
 .slide-container{
   // height: 300px;
    overflow: hidden;
    padding: 30px 0;
    .card {
    display: none;
    }
    .middle-card {
    display: block;
    flex: 0 0 100%;
    margin: 0;
    }

    &:after,
    &:before {
        display: none;
    }
 }

    
}
</style>  

<!-- 
<template>
    <div class="slide-container" @mouseenter="stopSlideShow" @mouseleave="startSlideShow">
        <div class="cards-wrapper">
            <div class="card1" :class="{ 'middle-card': index === 1 }" v-for="(card, index) in visibleCards" :key="index">
                {{ card }}
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';

export default {
    setup() {
        const cards = ref(['Card 1', 'Card 2', 'Card 3', 'Card 4', 'Card 5']);

        let interval = null;

        function rotateCards() {
            cards.value.push(cards.value.shift());
        }

        const visibleCards = computed(() => {
            const len = cards.value.length;
            const middleIndex = Math.floor(len / 2);
            return [
                cards.value[(middleIndex + len - 1) % len],
                cards.value[middleIndex],
                cards.value[(middleIndex + 1) % len],
            ];
        });

        function startSlideShow() {
            interval = setInterval(rotateCards, 2000);
        }

        function stopSlideShow() {
            clearInterval(interval);
        }

        onMounted(startSlideShow);
        onUnmounted(stopSlideShow);

        return {
            visibleCards,
            startSlideShow,
            stopSlideShow,
        };
    },
};
</script>

<style>
.slide-container {
    overflow: hidden;
    width: 60%;
    /* Container width */
    margin: auto;
    position: relative;
    height: 200px;
}

.cards-wrapper {
    display: flex;
    justify-content: center;
    /* Align items to the center */
    transition: transform 0.5s ease-in-out;
    position: absolute;
    left: 25%;
    height: 100px;
    /* Adjust based on the total width to center the cards */
    width: 150%;
    /* Total width to allow partial visibility */
}

.card1 {
    flex: 0 0 33.333%;
    /* Default size for cards */
    text-align: center;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    overflow: hidden;
}

.middle-card {
    flex: 0 0 50%;
    /* Make the middle card larger */
}

/* You might need to adjust the .cards-wrapper left or width properties to ensure proper alignment */
</style> -->
