<template>
    <div class="w-100 position-relative">
        <div class="striped-gradient"></div>
        <div class="container py-5">
            <h2 class="fw-700 text-xlarge res mb-5" style="color: #0000E2">{{ $t('faq.heading') }}</h2>
            <div class="row">
                <div class="accordion" id="accordionPanelsStayOpenExample">
                    <div class="accordion-item" v-for="qa in 5" :key="qa">
                        <h2 class="accordion-header">
                            <button class="accordion-button color-primary fw-700 text-large text-start p-4"
                                @click="toggleAccordion(qa)" type="button" :class="{ 'collapsed': qa != openedItem }"
                                data-bs-toggle="collapse" :data-bs-target="'#panelsStayOpen-' + qa" aria-expanded="true"
                                :aria-controls="'panelsStayOpen-' + qa">
                                {{ $t(`faq.faq_list.question${qa}.q`) }}
                            </button>
                        </h2>
                        <div :id="'panelsStayOpen-' + qa" class="accordion-collapse  "
                            :class="{ 'show': qa == openedItem, 'collapse': qa != openedItem }">
                            <div class="accordion-body text-start" v-html="$t(`faq.faq_list.question${qa}.a`)">

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row p-12">
                <router-link class="btn btn-warning m-auto w-auto my-4 mob_100_mob " to="/faq"> {{ $t('ShowMore')
                    }}</router-link>


            </div>
        </div>
    </div>


</template>

<script>
export default {
    name: "FAQ",
    data() {
        return {
            openedItem: 1,
        };
    },
   methods: {
       toggleAccordion(qa) {
           if (this.openedItem === qa) {
               // If the clicked item is already open, close it
               this.openedItem = null;
           } else {
               // If another item is clicked, open it and close the previously opened item
               this.openedItem = qa;
           }
       }
    },
}
</script>

<style lang="scss" scoped>
 @media (max-width: $breakpoint-small) {
    .p-12{
        padding: 12px;
    }
     .mob_100_mob{
        width: 100% !important;
     }


 }
</style>